<!--
--------------------------------------------------------------------------------
<copyright file="SalesFunnel.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget.sales_funnel') }}</summary>
      <p>{{ $t('widget_info_text.sales_funnel_txt') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <p class="tip-bkg">{{ $t('widget_info_text.bars_tip_txt_cat') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.sales_funnel_targets') }}</summary>
      <p>{{ $t('widget_info_text.sales_funnel_targets_text') }}</p>
      <ul class="list">
        <li class="list-item">{{ $t('widget_info_text.sales_funnel_targets_r1') }}</li>
        <li class="list-item">{{ $t('widget_info_text.sales_funnel_targets_r2') }}</li>
        <li class="list-item">{{ $t('widget_info_text.sales_funnel_targets_r3') }}</li>
        <li class="list-item">{{ $t('widget_info_text.sales_funnel_targets_r4') }}</li>
        <li class="list-item">{{ $t('widget_info_text.sales_funnel_targets_monthly_paid') }}</li>
      </ul>
      <p class="tip tip-bkg">{{ $t('widget_info_text.tip') }}</p>
      <p class="tip-bkg">{{ $t('widget_info_text.sales_funnel_targets_tip_text') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.tooltip') }}</summary>
      <p>{{ $t('widget_info_text.sales_funnel_tooltip_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('report.parameters') }}</summary>
      <img src="images/info/threeDotsMenu.jpg" class="list-item" />
      <p>{{ $t('widget_info_text.sales_funnel_parameters') }}</p>
    </details>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
